import { useBreakpointValue } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { graphql, PageProps } from "gatsby";
import React from "react";
import Image from "../components/Image";
import Page from "../components/Page";
import { PageTitle } from "../components/PageTitle";
import { PressQuote } from "../components/PressQuote";
import SEO from "../components/SEO";
import AnimatingTabs from "../components/Tabs";
import { YouTubeVideo } from "../components/YouTubeVideo";
import { PrismicPress, PrismicPressInterviews } from "../prismic-types";
import theme from "../theme";
import { mediaQuery } from "../theme/breakpoints";
import { innerHtmlFromPrismicRaw } from "../util";

type QueryData = {
  prismicPress: PrismicPress;
  prismicPressInterviews: PrismicPressInterviews;
};

export const query = graphql`
  {
    prismicPress {
      data {
        page_title {
          text
        }
        image_title {
          text
        }
        image_credit_link
        image_credit_name
        press_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body {
          ... on PrismicPressBodyPressReview {
            id
            primary {
              event
              publication_name
              review_author
              review_date
              review_link
              review_quote {
                text
              }
            }
          }
        }
      }
    }
    prismicPressInterviews {
      data {
        order_by_date
        body {
          ... on PrismicPressInterviewsBodyYoutubeVideo {
            id
            primary {
              blurb {
                raw
              }
              date
              title {
                text
              }
              youtube_url {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const PressQuotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${theme.space[5]};

  ${mediaQuery(theme.breakpoints.md)} {
    flex-direction: row;
  }
`;

const PressQuoteGroup = styled.div`
  flex-basis: 1;
  width: 100%;
  max-width: 100%;

  > div:last-child {
    margin-bottom: 0;
  }
`;

const getPressReviewData = (prismicPress: QueryData["prismicPress"]) => {
  const title = prismicPress?.data?.page_title?.text;
  const pressReviews = (prismicPress?.data?.body as any[]).map((it) => ({
    ...it.primary,
    // eslint-disable-next-line @typescript-eslint/camelcase
    review_date: new Date(it.primary.review_date)
  }));

  const imageData =
    prismicPress?.data?.press_image?.localFile?.childImageSharp?.fluid;
  const imageTitle = prismicPress?.data?.image_title?.text;
  const imageCreditTitle = prismicPress?.data?.image_credit_name;
  const imageCreditLink = prismicPress?.data?.image_credit_link;

  return {
    title,
    pressReviews,
    imageData,
    imageTitle,
    imageCreditTitle,
    imageCreditLink
  };
};

const getPressInterviewData = (
  prismicPressInterviews: QueryData["prismicPressInterviews"]
) => {
  const orderByDate = prismicPressInterviews.data?.order_by_date ?? false;

  const videos = prismicPressInterviews.data?.body?.map((v) => {
    return {
      title: v?.primary?.title?.text,
      date: v?.primary?.date,
      url: v?.primary?.youtube_url?.url,
      blurb: v?.primary?.blurb?.raw
    };
  });

  if (orderByDate) {
    videos?.sort((a, b) => (a.date > b.date ? -1 : 1));
  }

  return {
    orderByDate,
    videos
  };
};

const PressPage: React.FC<PageProps<QueryData>> = ({ data }) => {
  // TODO: Tighten typings for nulls
  const {
    title,
    pressReviews,
    imageData,
    imageTitle,
    imageCreditTitle,
    imageCreditLink
  } = getPressReviewData(data.prismicPress);

  const { videos, orderByDate } = getPressInterviewData(
    data.prismicPressInterviews
  );

  if (orderByDate) {
    videos?.sort((a, b) => (a.date > b.date ? -1 : 1));
  }

  // Behaviour is sm, md, ...rest (so we're saying it _should_ stack in sm, md breakpoints)
  const singleRow = useBreakpointValue([true, true, false]);

  pressReviews.sort((a, b) => (a.review_date > b.review_date ? -1 : 1));
  const lhPressQuotes: any[] = [];
  const rhPressQuotes: any[] = [];

  // If there is only a single row, we split in half,
  // If there are multiple we distribute l,r,l,r for date descending
  if (singleRow) {
    pressReviews.forEach((p, idx, arr) =>
      idx <= Math.floor(arr.length / 2)
        ? lhPressQuotes.push(p)
        : rhPressQuotes.push(p)
    );
  } else {
    pressReviews.forEach((p, idx) =>
      idx % 2 === 0 ? lhPressQuotes.push(p) : rhPressQuotes.push(p)
    );
  }

  return (
    <Page>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
      <SEO title={title!} />
      <PageTitle>{title}</PageTitle>
      <Image
        title={imageTitle || ""}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        credit={{ title: imageCreditTitle!, link: imageCreditLink! }}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        data={imageData!}
      />
      <div style={{ marginTop: theme.space[4] }} />
      <AnimatingTabs
        tabTitles={["Reviews", "Interviews"]}
        tabContents={[
          // Reviews
          <PressQuotesWrapper key={"press-reviews"}>
            <PressQuoteGroup>
              {(lhPressQuotes as any[]).map((pr, idx) => (
                <PressQuote
                  key={`press-review-lh-${idx}`}
                  event={pr.event}
                  publication={pr.publication_name}
                  author={pr.review_author}
                  link={pr.review_link}
                  quote={pr.review_quote.text}
                  date={new Date(pr.review_date)}
                />
              ))}
            </PressQuoteGroup>
            <PressQuoteGroup>
              {(rhPressQuotes as any[]).map((pr, idx) => (
                <PressQuote
                  key={`press-review-rh-${idx}`}
                  event={pr.event}
                  publication={pr.publication_name}
                  author={pr.review_author}
                  link={pr.review_link}
                  quote={pr.review_quote.text}
                  date={pr.review_date}
                />
              ))}
            </PressQuoteGroup>
          </PressQuotesWrapper>,
          videos?.map((v, idx) => {
            const blurbs = (Array.isArray(v.blurb)
              ? v.blurb
              : [v.blurb]
            ).map((b) => innerHtmlFromPrismicRaw(b));
            return (
              <YouTubeVideo
                key={`media-yt-${idx}`}
                title={v.title || ""}
                date={v.date || ""}
                url={v.url || ""}
                blurb={blurbs}
                mirrored={singleRow ? false : idx % 2 == 0}
                overspill={false}
              />
            );
          })
          // Interviews
        ]}
      />
    </Page>
  );
};

export default PressPage;
