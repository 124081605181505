import styled from "@emotion/styled";
import Img, { FluidObject } from "gatsby-image";
import React from "react";
import theme from "../theme";
import { mediaQuery } from "../theme/breakpoints";
import { Image as ImageProps } from "../types";
import { generateCaption } from "../util";
import { OVERSPILL_MEDIA_QUERY } from "../constants";
const StyledFigure = styled.figure`
  margin: 0;
  position: relative;
  z-index: 0;

  > figcaption {
    padding-bottom: ${theme.space[1]};
    padding-right: ${theme.space[2]};
    color: ${theme.colors.black};
    font-size: ${theme.fontSizes.xs};
    text-align: right;
  }
`;

const StyledImg = styled(Img)`
  margin-left: -${theme.space[2]};
  margin-right: -${theme.space[2]};

  ${mediaQuery(OVERSPILL_MEDIA_QUERY)} {
    margin-left: -${theme.space[6]};
    margin-right: -${theme.space[6]};
  }
`;

const Image: React.FC<ImageProps> = (props) => {
  const { title, credit, data } = props;

  let altText = `${title}`;
  if (credit) {
    altText = `${altText} - by ${credit.title}`;
  }

  return (
    <StyledFigure>
      <figcaption>{generateCaption(title, credit)}</figcaption>
      <StyledImg fluid={data as FluidObject} loading={"eager"} alt={altText} />
    </StyledFigure>
  );
};

export default Image;
