// - Displays information about a given publication's commentary
// - Link
// - Who
// - When
// - Quote
import styled from "@emotion/styled";
import moment from "moment";
import React from "react";
import theme from "../theme";

export interface PressQuoteProps {
  event: string;
  publication: string;
  date: Date;
  quote: string;
  link?: string;
  author?: string;
}

const StyledPressQuoteWrapper = styled.div`
  padding: ${theme.space[2]} ${theme.space[4]} ${theme.space[4]}
    ${theme.space[4]};
  margin: ${theme.space[10]} ${theme.space[4]};

  background: ${theme.colors.palette[50]};
  padding: ${theme.space[1]} ${theme.space[2]};

  h5 {
    font-size: ${theme.fontSizes.sm};
  }

  h4,
  h5 {
    margin: ${theme.space[2]} auto;
  }

  h4 {
    color: ${theme.colors.palette[700]};
    font-size: ${theme.fontSizes.md};
  }

  blockquote {
    margin: ${theme.space[6]} ${theme.space[1]} ${theme.space[0]}
      ${theme.space[1]};
  }

  blockquote:before,
  blockquote:after {
    color: ${theme.colors.palette[700]};
    font-family: ${theme.fonts.heading};
    font-style: italic;
    font-size: ${theme.fontSizes["4xl"]};
    line-height: 0.1em;
    margin-right: ${theme.space[3]};
    vertical-align: -0.3em;
  }

  blockquote:before {
    content: "“";
  }

  blockquote:after {
    content: "”";
  }

  blockquote i {
    font-family: ${theme.fonts.body};
    font-style: italic;
    font-size: ${theme.fontSizes.xl};
  }
`;

const title = (children: React.ReactNode, linkProp?: string) =>
  linkProp ? (
    <h4>
      <a href={linkProp} target="_blank" rel="noreferrer">
        {children}
      </a>
    </h4>
  ) : (
    <h4>{children}</h4>
  );

export const PressQuote: React.FC<PressQuoteProps> = (props) => {
  const formattedDate = moment(props.date).format("MMMM YYYY");

  const subtitle = props.author
    ? `${props.author} • ${props.publication} • ${formattedDate}`
    : `${props.publication} • ${formattedDate}`;

  return (
    <StyledPressQuoteWrapper>
      {title(props.event, props.link)}
      <h5>{subtitle}</h5>
      <blockquote>
        <i>{props.quote}</i>
      </blockquote>
    </StyledPressQuoteWrapper>
  );
};
